<template>
  <section class="hero" v-if="hasHeader">
    <div class="hero-body has-text-centered">
      <div class="container">
        <img class="pub-logo" :src="logo" alt="Logo" v-if="logo">
        <h1 id="header" class="title is-size-1" v-if="title" v-html="title" />
        <h2 id="subheader" class="subtitle" v-if="subtitle" v-html="subtitle" />
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      logo: 'publication_logo',
      title: 'survey_title',
      subtitle: 'survey_subtitle',
    }),
    hasHeader() {
      if (this.logo) return true;
      if (this.title) return true;
      return false;
    },
  },
};
</script>
